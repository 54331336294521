import { SET_STATUS_TEXT, SET_UNLINKABLE_MEASUREMENT } from './-mutation-types';
import { useLevelOfMeasurementsStore } from '~/stores/level-of-measurements';
import type { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';
import type { UnlinkableMeasurement } from '~/models/UnlinkableMeasurement';

const Mutations = {
    [SET_STATUS_TEXT](payload: LevelOfMeasurementsStatusText | null) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        levelOfMeasurementsStore.statusText = payload;
    },
    [SET_UNLINKABLE_MEASUREMENT](payload: UnlinkableMeasurement | null) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        levelOfMeasurementsStore.unlinkableMeasurement = payload;
    },
};

export default Mutations;
