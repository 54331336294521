import { GET_STATUS_TEXT, GET_UNLINKABLE_MEASUREMENT } from './-getter-types';
import { useLevelOfMeasurementsStore } from '~/stores/level-of-measurements';
import type { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';
import type { UnlinkableMeasurement } from '~/models/UnlinkableMeasurement';

const Getters = {
    [GET_STATUS_TEXT]: (): LevelOfMeasurementsStatusText | null => {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();

        return levelOfMeasurementsStore.statusText;
    },
    [GET_UNLINKABLE_MEASUREMENT]: (): UnlinkableMeasurement | null => {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();

        return levelOfMeasurementsStore.unlinkableMeasurement;
    },
};

export default Getters;
