import type { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';
import type { UnlinkableMeasurement } from '~/models/UnlinkableMeasurement';

export type ProductState = {
    statusText: LevelOfMeasurementsStatusText | null;
    unlinkableMeasurement: UnlinkableMeasurement | null;
}

export const defaultStateData = (): ProductState => ({
    statusText: null,
    unlinkableMeasurement: null,
});

export const DefaultState = (): ProductState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
