import { SET_STATUS_TEXT, SET_UNLINKABLE_MEASUREMENT } from './-mutation-types';
import { useLevelOfMeasurementsStore } from '~/stores/level-of-measurements';
import LevelOfMeasurementsStatusTextService from '~/services/api/LevelOfMeasurementsStatusTextService';
import UnlinkableMeasurementService from '~/services/api/UnlinkableMeasurementService';
import type { LevelOfMeasurementsStatusText } from '~/models/LevelOfMeasurementsStatusText';
import type { UnlinkableMeasurement } from '~/models/UnlinkableMeasurement';

const Actions = {
    async fetchStatusText(statusTextId: number) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        const statusTextService = new LevelOfMeasurementsStatusTextService();
        const response = await statusTextService.fetchStatusText(statusTextId);

        levelOfMeasurementsStore[SET_STATUS_TEXT](response);
    },
    async fetchUnlinkableMeasurement(unlinkableMeasurementId: number) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        const unlinkableMeasurementervice = new UnlinkableMeasurementService();
        const response = await unlinkableMeasurementervice.fetchUnlinkableMeasurement(unlinkableMeasurementId);

        levelOfMeasurementsStore[SET_UNLINKABLE_MEASUREMENT](response);
    },
    setStatusText(statusText: LevelOfMeasurementsStatusText) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        levelOfMeasurementsStore[SET_STATUS_TEXT](statusText);
    },
    setUnlinkableMeasurement(statusText: UnlinkableMeasurement) {
        const levelOfMeasurementsStore = useLevelOfMeasurementsStore();
        levelOfMeasurementsStore[SET_UNLINKABLE_MEASUREMENT](statusText);
    },
};

export default Actions;
