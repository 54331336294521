/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './level-of-measurements/state';
import Getters from './level-of-measurements/getters';
import Actions from './level-of-measurements/actions';
import Mutations from './level-of-measurements/mutations';

export const useLevelOfMeasurementsStore = defineStore(
    'level-of-measurements',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
